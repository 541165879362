import { NgModule } from '@angular/core';
import { MxvPlatformService } from './platform.service';

@NgModule({
  providers: [MxvPlatformModule],
})
export class MxvPlatformModule {
  /**
   * Constructor
   */
  constructor(private _mxvPlatformService: MxvPlatformService) {}
}
