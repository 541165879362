import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { EnvService } from 'src/app/shared/services/env/env.service';
import { environment } from 'src/environments/environment';

@Injectable({
  providedIn: 'root',
})
export class RentalPhotosService {
  constructor(private httpClient: HttpClient) {}

  getProperyPhotos(propertyId: number, smallDevice: boolean): Observable<any> {
    return this.httpClient
      .get<any>(
        `${environment.endpoints.api}/v1/property-photos/${propertyId}/${smallDevice}`
      )
      .pipe();
  }
}
