import { isPlatformBrowser } from '@angular/common';
import {
  AfterViewInit,
  Component,
  ElementRef,
  EventEmitter,
  Inject,
  Input,
  OnInit,
  Output,
  PLATFORM_ID,
  ViewChild,
  ViewEncapsulation
} from '@angular/core';
import { } from 'googlemaps';

@Component({
  selector: 'app-gautocomplete',
  templateUrl: './gautocomplete.component.html',
  styleUrls: ['./gautocomplete.component.css'],
  encapsulation: ViewEncapsulation.None,
})
export class GautocompleteComponent implements OnInit, AfterViewInit {
  @ViewChild('placesBoxContainer', { static: true })
  placesBox: ElementRef;
  @ViewChild('placesBoxContainer', { static: true }) addresstext: any;

  @Input()
  public place;

  @Input()
  public types;

  @Input()
  public class;

  @Output()
  public inputValueChanged: EventEmitter<any>;

  @Output()
  placesBoxChanged: EventEmitter<any>;

  actualText: string;


  constructor(@Inject(PLATFORM_ID) private platformId: {}) {
    this.placesBoxChanged = new EventEmitter();
    this.inputValueChanged = new EventEmitter();
    this.actualText = '';
  }

  ngOnInit() { }

  ngAfterViewInit(): void {
    if (isPlatformBrowser(this.platformId)) {
      const autocomplete = new google.maps.places.Autocomplete(
        this.addresstext.nativeElement,
        {
          types: this.types,
          componentRestrictions: { country: 'cy' },
        }
      );

      google.maps.event.addListener(autocomplete, 'places_changed', () => {
        const pp = autocomplete.getPlace()[0];
      });

      google.maps.event.addListener(autocomplete, 'place_changed', () => {
        const place = autocomplete.getPlace();
        this.placesBoxChanged.next(place);
      });
    }
  }
  onActualTextChanged() {

    this.inputValueChanged.next(this.actualText);

  }
  autocompleteFocus(): void { }
}
