<div class="d-block modal" @slideInRight>
  <div
    class="modal-dialog modal-dialog-centered modal-dialog-scrollable modal-fullscreen-sm-down"
  >
    <div class="modal-content shadow-lg">
      <div class="modal-header bg-primary text-white">
        <h5 class="modal-title">Sign Up</h5>
        <button
          type="button"
          class="btn-close text-white"
          data-dismiss="modal"
          aria-label="Close"
          (click)="dismiss('fromX')"
        ></button>
      </div>

      <form
        (ngSubmit)="submitVerificationCode(verifyForm.value, $event)"
        class="form-validate"
        [formGroup]="verifyForm"
        *ngIf="showVerifyCodeForm"
      >
        <div class="modal-body">
          <div
            class="alert alert-{{ verifyCodeAlert.type }}"
            role="alert"
            *ngIf="verifyCodeAlert.message != ''"
            [innerHtml]="verifyCodeAlert.message | safe : 'html'"
          ></div>

          <div class="alert">
            You need to verify your phone number. An sms with a 6 digit code was
            sent to your mobile
          </div>
          <div class="form-group mb-2 pb-4">
            <input
              type="text"
              class="form-control"
              id="code"
              [formControlName]="'code'"
              placeholder="Verification Code"
              autocomplete="false"
              autofocus="true"
            />
            <div class="invalid-feedback">Valid 6 Digit Code is required.</div>
            <!-- Submit  -->
          </div>

          <div class="form-group">
            <button type="submit" class="btn btn-success me-2">Verify</button>
            <button
              type="button"
              class="btn btn-warning me-2"
              (click)="showVerifyCodeForm=false;"
            >
              Go Back
            </button>
          </div>
        </div>
      </form>
      <form
        *ngIf="showRegisterForm"
        class="form-validate"
        [formGroup]="signUpForm"
        (ngSubmit)="onSubmit(signUpForm.value, $event)"
      >
        <div class="modal-body">
          <!-- Login -->
          <div class="px-md-3 px-xl-2">
            <div
              class="alert alert-{{ alert.type }}"
              role="alert"
              *ngIf="alert.message != '' "
              [innerHtml]="alert.message | safe : 'html'"
            ></div>

            <div class="form-floating mb-2">
              <input
                type="text"
                class="form-control"
                id="email"
                [formControl]="signUpForm.get('email')"
                placeholder="Email Address"
                autocomplete="false"
                value=""
                required=""
                [ngClass]="{'is-invalid': signUpForm.get('email').invalid && signUpForm.get('email').dirty}"
                autofocus="true"
              />
              <label for="email">Email</label>
              <div
                *ngIf="signUpForm.get('email').invalid && signUpForm.get('email').dirty"
                class="invalid-feedback"
              >
                Valid Email is required.
              </div>
            </div>

            <div class="form-floating mb-2">
              <input
                name="loginPassword"
                id="loginPassword"
                placeholder="Password"
                type="password"
                required
                data-msg="Please enter your password"
                class="form-control"
                [ngClass]="{'is-invalid': signUpForm.get('password').invalid && signUpForm.get('password').dirty}"
                [formControl]="signUpForm.get('password')"
              />
              <label for="loginPassword"> Password</label>
              <div
                *ngIf="signUpForm.get('password').invalid && signUpForm.get('password').dirty"
                class="invalid-feedback"
              >
                Password must be at least 8 characters long
              </div>
            </div>

            <div class="form-floating mb-2">
              <input
                name="repeatpassword"
                id="repeatpassword"
                placeholder="Repeat-Password"
                type="password"
                required
                data-msg="Please repeat your password"
                class="form-control"
                [ngClass]="{'is-invalid': signUpForm.get('repeatpassword').invalid && signUpForm.get('repeatpassword').dirty}"
                [formControl]="signUpForm.get('repeatpassword')"
              />
              <label for="repeatpassword">Repeat Password</label>

              <div
                class="invalid-feedback"
                *ngIf="signUpForm.get('repeatpassword').value !== signUpForm.get('password').value"
              >
                Password and Repeat password do not match
              </div>
            </div>
            <hr
              data-content="Contact Info"
              class="my-4 hr-text letter-spacing-2"
            />
            <div class="row">
              <div class="col-md-6 mb-2">
                <div class="form-floating">
                  <input
                    type="text"
                    class="form-control"
                    id="firstName"
                    placeholder="Firstname"
                    [formControl]="signUpForm.get('firstname')"
                    value=""
                    required=""
                  />
                  <label for="firstName">First name</label>

                  <div class="invalid-feedback">
                    Valid first name is required.
                  </div>
                </div>
              </div>
              <div class="col-md-6 mb-3">
                <div class="form-floating">
                  <input
                    type="text"
                    class="form-control"
                    id="lastName"
                    [formControl]="signUpForm.get('lastname')"
                    placeholder="Lastname"
                    value=""
                    required=""
                  />
                  <label for="lastName">Last name</label>
                  <div class="invalid-feedback">
                    Valid last name is required.
                  </div>
                </div>
              </div>
            </div>
            <div class="row">
              <div class="col-md-6 mb-3">
                <div class="form-floating">
                  <select
                    name="country"
                    id="country"
                    class="form-control"
                    placeholder="Country"
                    [formControl]="signUpForm.get('country')"
                    #countryField
                    (change)="countryOnChange()"
                  >
                    <option value="" selected></option>
                    <ng-container *ngFor="let country of isoCountries">
                      <option [value]="country.code">{{ country.name }}</option>
                    </ng-container>
                  </select>

                  <label for="country">Country</label>
                </div>
              </div>
              <div class="col-md-6 mb-3">
                <div class="form-floating">
                  <input
                    type="text"
                    class="form-control"
                    id="phone"
                    [formControl]="signUpForm.get('phone')"
                    placeholder="Phone Number"
                    value=""
                    required="true"
                  />
                  <label for="phone">Phone Number</label>
                </div>
              </div>
            </div>
          </div>
        </div>
        <div class="modal-footer mt-4">
          <a type="submit" class="btn btn-link"> Login </a>

          <!-- Submit  -->
          <button
            type="submit"
            class="btn btn-lg btn-primary"
            autofocus
            [disabled]="invalidFieldsExist()"
          >
            Sign Up
          </button>

          <a class="btn btn-lg btn-outline-danger" (click)="dismiss('fromX')">
            Cancel
          </a>
        </div>
      </form>

      <div class="modal-body" *ngIf="showFinished">
        <div class="py-4">
          Succesfuly Registered.
          <button class="btn btn-link" (click)="openLogin();">
            Click Here to Login
          </button>
        </div>
      </div>
    </div>
  </div>
</div>
