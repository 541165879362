<div class="p-3 p-lg-2 card border-0 my-2 shadow">
  <app-inline-calendar
    (datesSelected)="onDatesSelectedT($event)"
    (calendarIsVisible)="onCalendarVisibilityChanged($event)"
    [bookedDays]="bookedDays"
    [calendarLayout]="'popup'"
    [startDate]="startDate"
    [endDate]="endDate"
  >
  </app-inline-calendar>
  <button class="btn btn-link" (click)="clearDates()">Clear Dates</button>
</div>
