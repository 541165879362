import { Injectable, Inject } from '@angular/core';
import { Meta, Title } from '@angular/platform-browser';
import { DOCUMENT } from '@angular/common';

@Injectable({
  providedIn: 'root',
})
export class SeoService {
  constructor(
    private metaTagService: Meta,
    private titleService: Title,
    @Inject(DOCUMENT) private document: Document
  ) {}

  generateTags(config) {
    // default values
    config = {
      title: 'Myexclusive Villas. Luxury Holiday Rentals',
      description: 'Myexclusive villas - book your villa holiday or apartment.',
      image: 'https://a.myexclusivevillas.com/assets/img/mxvLogo.svg',
      slug: '',
      ...config,
    };

    this.metaTagService.updateTag({ name: 'twitter:card', content: 'summary' });
    this.metaTagService.updateTag({
      name: 'twitter:site',
      content: '@myexclusivev',
    });
    this.metaTagService.updateTag({
      name: 'twitter:title',
      content: config.title,
    });
    this.metaTagService.updateTag({
      name: 'twitter:description',
      content: config.description,
    });
    this.metaTagService.updateTag({
      name: 'twitter:image',
      content: config.image,
    });

    this.metaTagService.updateTag({ property: 'og:type', content: 'website' });
    this.metaTagService.updateTag({
      property: 'og:site_name',
      content: 'Myexclusive Villas',
    });
    this.metaTagService.updateTag({
      property: 'og:title',
      content: config.title,
    });
    this.metaTagService.updateTag({
      property: 'og:description',
      content: config.description,
    });
    this.metaTagService.updateTag({
      property: 'og:image',
      content: config.image,
    });
    this.metaTagService.updateTag({
      property: 'og:url',
      content: `${this.getCanonicalURL()}`,
    });
  }

  setTitle(title) {
    this.titleService.setTitle(title);
  }

  updateTag(metaTag) {
    this.metaTagService.updateTag(metaTag);
  }

  setCanonicalURL(url?: string) {
    const canURL = this.getCanonicalURL(url);
    const link: HTMLLinkElement = this.document.createElement('link');
    link.setAttribute('rel', 'canonical');
    this.document.head.appendChild(link);
    link.setAttribute('href', canURL);
  }

  getCanonicalURL(url?: string) {
    return url === undefined ? this.document.URL : url;
  }
}
