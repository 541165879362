export class SearchInfo {
  location: string;
  dates: string;
  adults: number;
  children: number;
  infants: number;
  placeId: string;
  priceRange: string;

  constructor() {
    this.location = '';
    this.dates = '';
    this.adults = 0;
    this.children = 0;
    this.infants = 0;
    this.placeId = '';
    this.priceRange = '';
  }
}
