import {
  animate,
  keyframes,
  style,
  transition,
  trigger,
} from '@angular/animations';

const accordion = trigger('accordion', [
  transition(':enter', [
    style({
      height: 0,
    }),
    animate(
      200,
      style({
        height: '*',
      })
    ),
  ]),
  transition(':leave', [
    animate(
      100,
      style({
        height: 0,
      })
    ),
  ]),
]);
export { accordion };
