import { Component, Inject, OnInit } from '@angular/core';
import { PopupPanelRef } from '../popup-panel/popup-panelref';
import { PANEL_DATA } from '../popup-panel/popup-panel-tokens';

@Component({
  selector: 'app-mxv-rangepicker',
  templateUrl: './mxv-rangepicker.component.html',
  styleUrls: ['./mxv-rangepicker.component.css'],
})
export class MxvRangepickerComponent implements OnInit {
  public dateRange: string;
  public startDate: Date;
  public endDate: Date;
  public smallDevice = false;
  bookedDays = [];
  constructor(
    private _popupPanelRef: PopupPanelRef,
    @Inject(PANEL_DATA) public data: any
  ) {}

  ngOnInit(): void {
    this.bookedDays = this.data.bookedDates ?? [];
    this.startDate = this.data?.startDate ?? undefined;
    this.endDate = this.data?.endDate ?? undefined;
    // this.smallDevice = this.data.smallDevice ?? false;
  }
  onDatesSelectedT(dt: { from: string; to: string }) {
    // this.searchInformation.dates = dt.from + '_' + dt.to;
    this.dateRange = `${dt.from}_${dt.to}`;
    // tslint:disable-next-line:quotemark
    this.startDate = new Date(dt.from);

    // tslint:disable-next-line:quotemark
    this.endDate = new Date(dt.to);

    this._popupPanelRef.close(dt);
  }

  dismiss(how: string): void {
    this._popupPanelRef.close(how);
  }

  clearDates(): void {
    this._popupPanelRef.close('');
  }

  onCalendarVisibilityChanged(event): void {
    if (!event.visible) {
      this.dismiss('fromX');
    }
  }
}
