<!-- Modal Fullscreen xl -->
<center-spinner spinnerColor="info" *ngIf="!loaded"></center-spinner>
<div
  @zoomIn
  @zoomOut
  class="modal shadow p-3 modal-fullscreen-xl"
  id="modal-fullscreen-xl"
  tabindex="-1"
  role="dialog"
  aria-hidden="true"
  *ngIf="!smallDevice"
>
  <div
    class="modal-dialog"
    role="document"
    [ngClass]="{ 'dialog-p': !smallDevice }"
  >
    <div class="modal-content">
      <div class="modal-body">
        <div
          class="py-2 ps-5 pe-2 bg-card shadow mxv-gallery-header d-flex justify-content-between text-white text-lg"
        >
          <div>
            <ng-container *ngIf="propertyPhotos.length > 0">
              {{ currentPosition + 1 }} / {{ propertyPhotos.length }}
            </ng-container>
          </div>
          <h3>{{ photoTitle }}</h3>
          <div>
            <button (click)="dismiss('x')" class="btn pe-2 py-1 text-white">
              <i class="fas fa-times fa-2x rounded-xl"></i>
            </button>
          </div>
        </div>
        <div class="owl-theme" id="big">
          <owl-carousel-o
            [options]="bigImageOptions"
            (changed)="
              thumb.to('thumb-' + $event.startPosition);
              bigChanged($event.startPosition)
            "
            #big
          >
            <ng-template
              carouselSlide
              [id]="'slide-' + i"
              *ngFor="let item of propertyPhotos; let i = index"
            >
              <div class="item">
                <img
                  [src]="
                    'https://cdn.purplecrm.eu/p/' + params.id + '/' + item.photo
                  "
                  alt="item.t"
                />
              </div>
            </ng-template>
          </owl-carousel-o>
        </div>
      </div>

      <owl-carousel-o
        [options]="thumbnailOptions"
        id="big"
        class="owl-theme"
        id="thumbs"
        #thumb
      >
        <ng-template
          carouselSlide
          *ngFor="let item of propertyPhotos; let i = index"
          [id]="'thumb-' + i"
        >
          <div
            class="item"
            (click)="moveSlideto(i)"
            [ngClass]="{ current: i === currentPosition }"
          >
            <div
              [ngStyle]="{
                'background-image':
                  'url(https://cdn.purplecrm.eu/p/' +
                  params.id +
                  '/' +
                  item.photo +
                  ')'
              }"
            ></div>
          </div>
        </ng-template>
      </owl-carousel-o>
    </div>
  </div>
</div>
<!-- Modal Fullscreen xl -->
<div
  @zoomIn
  @zoomOut
  class="modal shadow p-0 modal-fullscreen"
  tabindex="-1"
  role="dialog"
  aria-hidden="true"
  *ngIf="smallDevice"
>
  <div
    class="modal-dialog"
    role="document"
    [ngClass]="{ 'dialog-p': smallDevice }"
  >
    <div class="modal-content">
      <div class="modal-header">
        <h5 class="modal-title" id="exampleModalLabel">
          {{ propertyInfo.holidaytitle }}
        </h5>
        <button
          type="button"
          class="btn-close"
          data-dismiss="modal"
          aria-label="Close"
          (click)="dismiss('x')"
        >
          <span aria-hidden="true" class="text-xl">&times;</span>
        </button>
      </div>

      <div class="modal-body p-0">
        <div
          class="card border-0 border-y"
          *ngFor="let photo of propertyPhotos"
        >
          <div class="overflow-hidden gradient-overlay">
            <img
              alt="item.t"
              class="img-fluid"
              [src]="
                'https://cdn.purplecrm.eu/p/' + params.id + '/' + photo.photo
              "
            />
          </div>
          <div class="card-body d-flex align-items-center">
            <div class="w-100">
              <h6 class="card-title title-wrap">
                {{ photo.t }}
              </h6>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</div>
