import { NgModule, Optional, SkipSelf } from '@angular/core';
import { MxvPlatformModule } from './services/platform/platform.module';

@NgModule({
  imports: [MxvPlatformModule],
})
export class TolisModule {
  /**
   * Constructor
   */
  constructor(@Optional() @SkipSelf() parentModule?: TolisModule) {
    if (parentModule) {
      throw new Error(
        'TolisModule has already been loaded. Import this module in the AppModule only!'
      );
    }
  }
}
