//import { Layout } from 'app/layout/layout.types';

import { Layout } from './layout/layout.types';

// Types
export type Scheme = 'auto' | 'dark' | 'light';
export type Screens = { [key: string]: string };

/**
 * AppConfig interface. Update this interface to strictly type your config
 * object.
 */
export interface AppConfig {
  layout: Layout;
  scheme: Scheme;
  screens: Screens;
}

/**
 * Default configuration for the entire application. This object is used by
 * configService to set the default configuration.
 *
 * Can store global configuration for the app here. use this
 * object to set the defaults. To access, update and reset the config, use
 * configService and its methods.
 *
 * "Screens" are carried over to the BreakpointObserver for accessing them within
 * components, and they are required.
 *
 */
export const appConfig: AppConfig = {
  layout: 'default',
  scheme: 'light',
  screens: {
    sm: '600px',
    md: '960px',
    lg: '1280px',
    xl: '1440px',
  },
};
