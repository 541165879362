import {
  AfterViewChecked,
  AfterViewInit,
  ChangeDetectorRef,
  Component,
  ElementRef,
  OnDestroy,
  OnInit,
  ViewChild,
  ViewEncapsulation,
} from '@angular/core';
import {
  UntypedFormBuilder,
  UntypedFormGroup,
  Validators,
} from '@angular/forms';

import { Subject } from 'rxjs';
import { mxvAnimations } from 'src/@tolis/animations/public-api';

import { AuthcheckService } from 'src/app/services/authcheck.service';
import {
  AuthenticationService,
  LoginData,
} from 'src/app/services/authentication.service';
import { TokenService } from 'src/app/services/token.service';
import { SignupDialog } from '../signup/signup.dialog';
import { TolisDialogService } from '../tolis-dialog/tolis-dialog.service';
import { TolisDialogRef } from '../tolis-dialog/tolis-dialogref';

@Component({
  selector: 'dialog-login',
  // tslint:disable-next-line:no-host-metadata-property
  host: { class: 'dialog-login' },
  templateUrl: './login.component.html',
  styleUrls: ['./login.component.css'],
  encapsulation: ViewEncapsulation.None,
  animations: mxvAnimations,
})
export class LoginComponent implements Login, OnInit, AfterViewInit, OnDestroy {
  unsubscribe: Subject<void> = new Subject();
  loginForm: UntypedFormGroup;

  loginError: LoginError;
  working = false;
  signup = false;

  @ViewChild('loginRef', { static: true }) loginElement: ElementRef;
  auth2: any;

  constructor(
    private authService: AuthenticationService,
    private tokenService: TokenService,
    private formBuilder: UntypedFormBuilder,
    private authCheckService: AuthcheckService,
    private _dialogRef: TolisDialogRef,
    private _dialogService: TolisDialogService
  ) {}

  dismiss(how: string) {
    this._dialogRef.close(how);
    //this.close();
  }

  ngOnInit(): void {
    this.loginError = new LoginError();

    this.loginForm = this.formBuilder.group({
      email: ['', [Validators.required, Validators.email]],
      password: ['', [Validators.required, Validators.minLength(8)]],

      // rememberme: [false]
    });
    this.auth2 = null;
    // this.googleSDK();
  }

  get c() {
    return this.loginForm.controls;
  }

  openSignupDialog(): void {
    const tolisDialogRef = this._dialogService
      .open(SignupDialog, {
        data: {
          slideIn: true,
        },
      })
      .afterClosed()
      .subscribe((message) => {
        if (message === 'fromX') {
          this.dismiss('signup');
        }
      });
    // this.dismiss('signup');
  }
  /** Succesful logins are handled here */

  handleResponse(loginData: LoginData): void {
    // this.tokenService.set(loginData.access_token);

    if (this.tokenService.handle(loginData.access_token)) {
      setTimeout(() => {
        this.authCheckService.changeAuthStatus(true);
        this.working = false;
        this.dismiss('loggedin');
      });
    } else {
      this.working = false;
    }
    // if (this.tokenService.handle(loginData.access_token)) {
    //   this.authCheckService.changeAuthStatus(true);
    // }
  }

  onSubmit(values, event): void {
    event.preventDefault();
    this.working = true;
    this.authService.login(values).subscribe(
      (loginData) => this.handleResponse(loginData),
      (error) => this.handleError(error)
    );
  }

  handleError(error: LoginError): void {
    if (error.message) {
      this.loginError = error;
    }
    this.working = false;
  }

  ngAfterViewInit() {
    // this.checkIfFacebookIsBlocked();
    // this.fbLibrary();
    // this.googleSDK();
    // this.loginElement.nativeElement.focus();
  }

  checkIfFacebookIsBlocked() {
    const self = this;
    fetch('https://connect.facebook.net/en_US/sdk.js', { mode: 'cors' })
      .then(() => {
        // console.log('Facebook sdk is allowed:');
        self.fbLibrary();
      })
      .catch(() => {
        self.fbLibrary();
        // console.log('Facebook sdk is NOT allowed:');
        // handle the case that facebook is not allowed
      });
  }

  fbLibrary() {
    (window as any).fbAsyncInit = () => {
      // tslint:disable-next-line:no-string-literal
      window['FB'].init({
        appId: '662470494496653',
        cookie: false,
        xfbml: true,
        version: 'v3.1',
      });
      // tslint:disable-next-line:no-string-literal
      window['FB'].AppEvents.logPageView();
    };

    // tslint:disable-next-line:only-arrow-functions
    (function (d, s, id) {
      let js;
      const fjs = d.getElementsByTagName(s)[0];
      if (d.getElementById(id)) {
        return;
      }
      js = d.createElement(s);
      js.id = id;
      js.src = '/assets/js/facebook-sdk.js';
      fjs.parentNode.insertBefore(js, fjs);
    })(document, 'script', 'facebook-jssdk');
  }

  googleSDK() {
    // tslint:disable-next-line:no-string-literal
    window['googleSDKLoaded'] = () => {
      window['gapi'].load('auth2', () => {
        this.auth2 = window['gapi'].auth2.init({
          client_id:
            '1069391258613-vo4nhj6td2ca6fcpdgtedgfjcne3omfa.apps.googleusercontent.com',
          cookiepolicy: 'single_host_origin',
          scope: 'profile email',
        });
        this.prepareLoginButton();
      });
    };
    (function (d, s, id) {
      var js,
        fjs = d.getElementsByTagName(s)[0];
      //if (d.getElementById(id)) { return; }
      js = d.createElement(s);
      js.id = id;
      js.src = 'https://apis.google.com/js/platform.js?onload=googleSDKLoaded';
      fjs.parentNode.insertBefore(js, fjs);
    })(document, 'script', 'google-jssdk');
  }

  prepareLoginButton() {
    this.auth2.attachClickHandler(
      this.loginElement.nativeElement,
      {},
      (googleUser) => {
        this.working = true;
        const gprofile = googleUser.getBasicProfile();

        this.authService
          .loginByGoogle({
            profile: gprofile,
            token: googleUser.getAuthResponse().access_token,
          })
          .subscribe(
            (loginData) => this.handleResponse(loginData),
            (error) => console.log(error)
          );

        // YOUR CODE HERE
      },
      (error) => {
        // console.log(JSON.stringify(error, undefined, 2));
      }
    );
  }

  loginFB() {
    this.working = true;

    window['FB'].login(
      (response) => {
        if (response.authResponse) {
          this.authService
            .loginByFacebook(response.authResponse.accessToken)

            .subscribe(
              (loginData) => this.handleResponse(loginData),
              (error) => console.log(error)
            );
        }
      },
      { scope: 'public_profile,email' }
    );
  }

  ngOnDestroy() {
    this.unsubscribe.next();
    this.unsubscribe.complete();
  }
}

export class LoginError {
  status: number;
  message: string;

  constructor() {
    this.status = 0;
    this.message = '';
  }
}

export interface Login {}
