import { BreakpointObserver } from '@angular/cdk/layout';
import {
  AfterViewChecked,
  ChangeDetectionStrategy,
  ChangeDetectorRef,
  Component,
  EventEmitter,
  Input,
  OnChanges,
  OnDestroy,
  OnInit,
  Output,
  SimpleChanges,
  ViewEncapsulation,
} from '@angular/core';
import * as dayjs from 'dayjs';
import { Subject, takeUntil } from 'rxjs';
import { fuseAnimations } from 'src/app/animations';

@Component({
  selector: 'app-inline-calendar',
  templateUrl: './inline-calendar.component.html',
  styleUrls: ['./inline-calendar.component.css'],
  encapsulation: ViewEncapsulation.None,
  animations: fuseAnimations,
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class InlineCalendarComponent
  implements OnInit, OnChanges, AfterViewChecked, OnDestroy
{
  private _unsubscribeAll: Subject<any> = new Subject<any>();

  @Input() bookedDays;
  @Input() startDate;
  @Input() endDate;
  @Input() calendarLayout = 'inline';
  smallDevice = false;
  @Input() initElement = '';
  @Input() calendarOptions: CalendarOptions = new CalendarOptions();

  @Output() datesSelected = new EventEmitter<{ from: string; to: string }>();
  @Output() calendarIsVisible = new EventEmitter<{ visible: boolean }>();
  isVisible = false;

  daysToDisable = [];
  public days: any[];
  public nextMonthDays: any[];
  public selectionLabel = 'Select Your Dates';
  public dayIsHovered = null;
  selectedStartDate = null;
  selectedEndDate = null;
  fakeEndDate = null;

  startMonth = dayjs().startOf('month');
  nextMonth = dayjs().startOf('month').add(1, 'month');

  constructor(
    private changeDetector: ChangeDetectorRef,
    private _observer: BreakpointObserver
  ) {
    // this.startMonth = moment().startOf('month');
    // this.nextMonth = moment().startOf('month').add(1, 'months');

    this.daysToDisable = [];
    this.bookedDays = [];
  }

  buildCalendar(): void {
    this.startMonth = dayjs(this.startDate).startOf('month');

    this.nextMonth = this.startMonth.add(1, 'month');

    this.days = [...Array(this.startMonth.daysInMonth())].map((_, i) =>
      this.startMonth.clone().add(i, 'day')
    );
    this.nextMonthDays = [...Array(this.nextMonth.daysInMonth())].map((_, i) =>
      this.nextMonth.clone().add(i, 'day')
    );
    this.selectedStartDate = null;
    this.selectedEndDate = null;

    if (this.startDate && this.endDate) {
      this.selectedStartDate = null;
      this.selectedEndDate = null;

      const dtStart = dayjs(this.startDate);
      const dtEnd = dayjs(this.endDate);
      this.onCalendarClicked(dtStart, false);
      this.onCalendarClicked(dtEnd, false);
    }
  }
  ngOnInit() {
    this.buildCalendar();
    this._observer
      .observe('(max-width: 784px)')
      .pipe(takeUntil(this._unsubscribeAll))
      .subscribe((result) => {
        this.smallDevice = result.matches;
      });
  }
  ngOnDestroy(): void {
    this._unsubscribeAll.next(null);
    this._unsubscribeAll.complete();
  }

  ngAfterViewChecked(): void {
    this.changeDetector.markForCheck();
  }

  ngOnChanges(changes: SimpleChanges): void {
    if (changes.startDate) {
      this.startDate = changes.startDate.currentValue;
      this.buildCalendar();
    }
    if (changes.bookedDays) {
      if (changes.bookedDays.currentValue) {
        changes.bookedDays.currentValue.forEach((d) => {
          const dates = this.getDates(
            new Date(d.from_date),
            new Date(d.to_date)
          );
          dates.shift();
          this.daysToDisable = Array.from(
            new Set(this.daysToDisable.concat(dates))
          );
        });

        // start and end dates
        // this.close();
        // this.open();
      }
    }
  }

  getDates(startDate, endDate) {
    const dayInterval = 1000 * 60 * 60 * 24; // 1 day
    const duration = endDate - startDate;
    const steps = duration / dayInterval;
    return Array.from(
      { length: steps },
      (v, i) =>
        new Date(startDate.valueOf() + dayInterval * i)
          .toISOString()
          .split('T')[0]
    );
  }

  column(index, m) {
    if (index === 0) {
      return m[0].day() + 1;
    }
  }

  today(day) {
    return dayjs().isSame(day, 'day');
  }

  isOccupied(day, doMoment?: boolean) {
    const isoDate = doMoment
      ? day.format('YYYY-MM-DD')
      : day.toISOString().split('T')[0];

    const today = dayjs();

    if (day.diff(today, 'days') < 0) {
      return true;
    }

    return this.daysToDisable.includes(isoDate);
  }

  next() {
    this.startMonth = this.startMonth.add(1, 'month');

    this.nextMonth = this.nextMonth.add(1, 'month');

    this.days = [...Array(this.startMonth.daysInMonth())].map((_, i) =>
      this.startMonth.clone().add(i, 'day')
    );
    this.nextMonthDays = [...Array(this.nextMonth.daysInMonth())].map((_, i) =>
      this.nextMonth.clone().add(i, 'day')
    );
  }

  prev() {
    this.startMonth = this.startMonth.add(-1, 'month');
    this.nextMonth = this.nextMonth.add(-1, 'month');

    this.days = [...Array(this.startMonth.daysInMonth())].map((_, i) =>
      this.startMonth.clone().add(i, 'day')
    );
    this.nextMonthDays = [...Array(this.nextMonth.daysInMonth())].map((_, i) =>
      this.nextMonth.clone().add(i, 'day')
    );
  }

  onCalendarClicked(day, close: boolean = true): boolean {
    // if this is a single select calendar just return the date clicked
    if (!this.calendarOptions.multiple) {
      this.datesSelected.emit(day);

      return true;
    }
    // if day clicked not available return
    if (this.isOccupied(day, true)) {
      this.selectionLabel = 'Dates are not Available';
      return false;
    }

    // new selection
    if (this.selectedStartDate !== null && this.selectedEndDate !== null) {
      this.selectedEndDate = null;
      this.selectedStartDate = day;
      this.selectionLabel = day.format('DD MMM, YYYY');

      return true;
    }

    if (this.selectedStartDate === null && this.selectedEndDate === null) {
      this.selectedStartDate = day;
      this.selectionLabel = day.format('DD MMM, YYYY');

      return true;
    }

    if (this.selectedStartDate !== null && this.selectedEndDate === null) {
      // if enddate is before startdate swap them

      if (day.diff(this.selectedStartDate) < 0) {
        const newDay = day;
        day = this.selectedStartDate;
        this.selectedStartDate = newDay;
      }

      const startDate = new Date(this.selectedStartDate.format('YYYY-MM-DD'));
      const endDate = new Date(day.format('YYYY-MM-DD'));
      const totalNights =
        day.diff(this.selectedStartDate) / (1000 * 60 * 60 * 24);
      // check if there are unavailable dates between start and end first
      const dates = this.getDates(startDate, endDate);
      const intersection = dates.filter((x) => this.daysToDisable.includes(x));

      this.selectedEndDate = intersection.length > 1 ? null : day;
      // reset start date if this is false
      this.selectedStartDate =
        intersection.length > 1 ? null : this.selectedStartDate;
      this.selectionLabel =
        intersection.length > 1
          ? 'Dates Are not Available'
          : this.selectedStartDate.format('DD MMM, YYYY ') +
            ' - ' +
            day.format('DD MMM, YYYY ') +
            ' | ' +
            Math.round(totalNights) +
            ' Nights';

      // emit if valid
      if (intersection.length < 1) {
        if (close) {
          // this will emmit and close
          this.datesSelected.emit({
            from: this.selectedStartDate.format('YYYY-MM-DD'),
            to: this.selectedEndDate.format('YYYY-MM-DD'),
          });
        }
      }

      return intersection.length < 1;
    }

    this.selectedStartDate = null;
    this.selectedEndDate = null;
    this.selectedStartDate = day;
    return true;
  }

  close() {
    this.calendarIsVisible.emit({ visible: false });
  }
  open() {
    this.calendarIsVisible.emit({ visible: true });
  }

  // @HostListener('document:click', ['$event'])
  // clickout(event) {
  //   if (
  //     !this.eRef.nativeElement.contains(event.target) &&
  //     event.target.name !== 'calendarTrigger'
  //   ) {
  //     this.close();
  //   }
  // }

  setFakeEndDate(nmday) {
    this.fakeEndDate = nmday;
  }
}

export interface ICalendarOptions {
  showTitle: boolean;
  multiple: boolean;
}

export class CalendarOptions implements ICalendarOptions {
  public showTitle: boolean = true;
  public multiple: boolean = true;
}
