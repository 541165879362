<app-toaster></app-toaster>

<!-- ----------------------------------------------------------------------------------------------------- -->
<!-- Empty layout. No header and footer -->
<!-- ----------------------------------------------------------------------------------------------------- -->
<empty-layout *ngIf="layout === 'empty'"></empty-layout>

<!-- ----------------------------------------------------------------------------------------------------- -->
<!-- Default layout -->
<!-- ----------------------------------------------------------------------------------------------------- -->
<default-layout *ngIf="layout === 'default'"></default-layout>
