export const environment = {
  production: true,
  application: {
    name: 'mxv-frontend',
    angular: 'Angular 15',
    bootstrap: 'Bootstrap 4.4.1',
  },
  apiUrl: 'https://a.myexclusivevillas.com',
  endpoints: {
    api: 'https://a.myexclusivevillas.com/api',
    login: 'https://a.myexclusivevillas.com/api/auth/login',
    logout: 'https://a.myexclusivevillas.com/api/auth/logout',
    signup: 'https://a.myexclusivevillas.com/api/auth/signup',
    storage: 'https://a.myexclusivevillas.com/storage',
  },
  siteUrl: 'https://a.myexclusivevillas.com',
  iss: {
    login: 'https://server.myexclusivevillas.com/auth/login',
    signup: 'https://server.myexclusivevillas.com/auth/signup',
    google: 'https://server.myexclusivevillas.com/auth/login/google/callback',
    facebook:
      'https://server.myexclusivevillas.com/auth/login/facebook/callback',
  },
  cdnUrl: 'https://cdn.purplecrm.eu',
};
