<div class="d-block modal">
  <div
    class="modal-dialog modal-dialog-centered modal-dialog-scrollable modal-fullscreen-sm-down"
    @slideInRight
  >
    <div class="modal-content shadow-lg">
      <div class="modal-header bg-secondary text-white">
        <h5 class="modal-title">Login</h5>
        <button
          type="button"
          class="btn-close"
          data-dismiss="modal"
          aria-label="Close"
          (click)="dismiss('fromX')"
        ></button>
      </div>

      <div class="modal-body">
        <!-- Login -->
        <div class="px-md-3 px-xl-2">
          <form
            autocomplete="off"
            class="form-validate"
            [formGroup]="loginForm"
            (ngSubmit)="onSubmit(loginForm.value, $event)"
          >
            <div class="form-group">
              <label for="loginUsername" class="form-label">
                Email Address</label
              >
              <input
                placeholder="Your Email"
                autocomplete="off"
                class="form-control form-control-lg"
                formControlName="email"
                autofocus="false"
              />
            </div>
            <div class="form-group mb-4">
              <div class="row">
                <div class="col">
                  <label for="loginPassword" class="form-label">
                    Password</label
                  >
                </div>
              </div>
              <input
                name="loginPassword"
                id="loginPassword"
                placeholder="Password"
                type="password"
                required
                data-msg="Please enter your password"
                class="form-control form-control-lg"
                formControlName="password"
              />
              <div class="row">
                <div class="col">
                  <a href="#" class="form-text small">Forgot password?</a>
                </div>
              </div>
            </div>
            <!-- Submit-->
            <div class="alert alert-danger" *ngIf="loginError.status > 0">
              {{ loginError.message }}
            </div>

            <div class="d-grid gap-1">
              <input
                type="submit"
                value="Sign In"
                class="btn btn-lg btn-primary mb-8"
                autofocus
                [disabled]="c.email.invalid"
              />
            </div>
            <hr data-content="OR" class="mt-5 hr-text letter-spacing-2" />
          </form>
          <div class="text-center">Don't have an Account yet?</div>

          <button
            class="btn btn-lg btn-block btn-link"
            (click)="openSignupDialog()"
            type="button"
          >
            Sign Up
          </button>
        </div>
      </div>
      <div class="modal-footer">
        <button
          type="button"
          class="btn btn-outline-danger"
          (click)="dismiss('fromX')"
        >
          Close
        </button>
      </div>
    </div>
  </div>
</div>
