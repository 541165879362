import { BreakpointObserver } from '@angular/cdk/layout';
import { isPlatformBrowser, Location } from '@angular/common';
import {
  ChangeDetectionStrategy,
  ChangeDetectorRef,
  Component,
  Inject,
  OnDestroy,
  OnInit,
  PLATFORM_ID,
  ViewEncapsulation,
  ViewChild,
  HostListener,
} from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { OwlOptions, SlidesOutputData } from 'ngx-owl-carousel-o';
import { Subject } from 'rxjs';
import { takeUntil } from 'rxjs/operators';
import { mxvAnimations } from 'src/@tolis/animations';
import { DIALOG_DATA } from 'src/app/dialogs/tolis-dialog/tolis-dialog-tokens';
import { TolisDialogRef } from 'src/app/dialogs/tolis-dialog/tolis-dialogref';
import { RentalPhotosService } from './rental-photos.service';

@Component({
  selector: 'app-rental-photos-standalone',
  templateUrl: './rental-photos-standalone.component.html',
  styleUrls: ['./rental-photos-standalone.component.css'],
  encapsulation: ViewEncapsulation.None,
  changeDetection: ChangeDetectionStrategy.OnPush,
  animations: mxvAnimations,
})
export class RentalPhotosStandaloneComponent implements OnInit, OnDestroy {
  @ViewChild('big') bigCarousel;

  public propertyPhotos: { photo: string; displayorder: number; t: string }[];
  public propertyInfo: { id: number | string; holidaytitle: string } = {
    id: 0,
    holidaytitle: '',
  };
  public currentPosition = 0;
  public smallDevice = false;
  public loaded = false;

  public params: {
    id: number;
    start: number;
  };
  public photoTitle = '';

  private loop: boolean;

  private propertyId = 0;
  private startPosition = 0;

  /** Check if we are on server or client */
  isBrowser = isPlatformBrowser(this.platformId);
  bigImageOptions: OwlOptions = {
    items: 1,
    animateOut: 'fadeOut',
    animateIn: 'fadeIn',
    navSpeed: 10,
    // smartSpeed: 200,
    nav: true,
    loop: true,
    mouseDrag: true,
    touchDrag: true,
    pullDrag: false,

    autoplay: false,
    dots: false,
    // responsiveRefreshRate: 200,
    navText: [
      '<i class="fa fa-arrow-left" aria-hidden="true"></i>',
      '<i class="fa fa-arrow-right" aria-hidden="true"></i>',
    ],
    lazyLoad: true,
    responsive: {
      0: {
        items: 1,
      },
      600: {
        items: 1,
      },
      1000: {
        items: 1,
      },
    },
  };
  thumbnailOptions: OwlOptions = {
    dots: false,
    nav: true,
    animateOut: 'fadeOut',
    animateIn: 'fadeIn',

    navText: [
      '<i class="fa fa-arrow-left" aria-hidden="true"></i>',
      '<i class="fa fa-arrow-right" aria-hidden="true"></i>',
    ],
    smartSpeed: 200,
    navSpeed: 10,
    loop: true,
    center: true,
    // slideBy: 6,
    autoHeight: false,
    autoWidth: true,
    // responsiveRefreshRate: 100,
    responsive: {
      0: {
        items: 3,
      },
      400: {
        items: 3,
      },
      550: {
        items: 4,
      },
      769: {
        items: 5,
      },
      940: {
        items: 6,
      },
      1200: {
        items: 8,
      },
    },
    lazyLoad: true,
  };

  slideShowOptions: OwlOptions = {
    loop: true,
    mouseDrag: true,
    touchDrag: true,
    pullDrag: true,
    items: 1,

    navText: [
      '<span class="btn btn-light"><i class="fas fa-chevron-left"></i></span>',
      '<span class="btn btn-light"><i class="fas fa-chevron-right"></i></span>',
    ],
    startPosition: 0,
    center: true,
    margin: 10,

    nav: true,
    dots: false,
    lazyLoad: false,
  };

  private _unsubscribeAll: Subject<any> = new Subject<any>();

  constructor(
    @Inject(PLATFORM_ID) private platformId: {},
    private photosService: RentalPhotosService,
    private observer: BreakpointObserver,
    private activatedRoute: ActivatedRoute,
    private cdref: ChangeDetectorRef,
    private _router: Router,
    private _dialogRef: TolisDialogRef,
    @Inject(DIALOG_DATA) public data: any
  ) {
    this.params = {
      id: -1,
      start: 0,
    };
    this.propertyPhotos = [];
  }

  carouselOptions: OwlOptions = {
    items: 1,
    nav: true,
    autoplay: false,
    dots: true,
    loop: true,
    responsiveRefreshRate: 200,
    navText: [
      '<svg width="100%" height="100%" viewBox="0 0 11 20"><path style="fill:none;stroke-width: 1px;stroke: #000;" d="M9.554,1.001l-8.607,8.607l8.607,8.606"/></svg>',
      '<svg width="100%" height="100%" viewBox="0 0 11 20" version="1.1"><path style="fill:none;stroke-width: 1px;stroke: #000;" d="M1.054,18.214l8.606,-8.606l-8.606,-8.607"/></svg>',
    ],
  };

  dismiss(how: string) {
    this._dialogRef.close(how);
  }

  goBack() {
    // this.location.back();
    //return back to property
    this._router.navigateByUrl(
      '/accomodation/' +
        this.propertyInfo.id +
        '/' +
        this.propertyInfo.holidaytitle.split(' ').join('-')
    );
  }

  ngOnInit() {
    if (this.data) {
      this.propertyId = this.data.id;
      this.startPosition = this.data.position;
    } else {
      this.propertyId = Number(
        this.activatedRoute.snapshot.paramMap.get('rentalId')
      );

      this.startPosition = Number(
        this.activatedRoute.snapshot.paramMap.get('startpos')
      );
    }

    this.slideShowOptions.startPosition = this.startPosition;

    this.params = {
      id: this.propertyId,
      start: this.startPosition,
    };
    if (this.isBrowser) {
      this.observer
        .observe('(max-width: 784px)')
        .pipe(takeUntil(this._unsubscribeAll))
        .subscribe((result) => {
          this.smallDevice = result.matches;
          this.cdref.markForCheck();

          // this.getPropertyPhotos();
        });
    }

    this.getPropertyPhotos();
  }

  ngOnDestroy(): void {
    this._unsubscribeAll.next(null);
    this._unsubscribeAll.complete();
  }

  getPropertyPhotos(): void {
    this.photosService
      .getProperyPhotos(this.propertyId, this.smallDevice)
      .subscribe((response) => {
        this.propertyPhotos = response.data;
        this.propertyInfo = response.property;

        this.slideShowOptions.startPosition = this.startPosition;
        this.loaded = true;
        this.thumbChanged(this.startPosition);
        this.cdref.markForCheck();
      });
  }

  thumbChanged(position: number): void {
    if (this.bigCarousel) {
      this.bigCarousel.to('slide-' + position);
      this.photoTitle = this.propertyPhotos[position]?.t;
      setTimeout(() => {
        this.bigCarousel.to('slide-' + position);
        this.photoTitle = this.propertyPhotos[position]?.t;
      }, 200);
    }
  }
  bigChanged(position: number): void {
    if (this.bigCarousel) {
      this.currentPosition = position;
      this.photoTitle = this.propertyPhotos[position].t;
    }
  }

  getSlideData(data: SlidesOutputData) {
    this.currentPosition = data.startPosition;
    if (this.loaded) {
      setTimeout(() => {
        // this.currentPosition = data.startPosition + 1;
        // this.photoTitle = this.propertyPhotos[data.startPosition].t;
      }, 1);
    }
  }

  moveSlideto(index: number | string): void {
    this.bigCarousel.to('slide-' + index);
  }
  @HostListener('document:keydown', ['$event.key'])
  private handleEscapeKey(key: string) {
    if (key === 'Escape') {
      this.dismiss('esc');
    } else if (key === 'ArrowRight') {
      this.bigCarousel.next();
    } else if (key === 'ArrowLeft') {
      this.bigCarousel.prev();
    } else if (key === 'ArrowUp') {
      this.bigCarousel.to('slide-0');
    } else if (key == 'ArrowDown') {
      this.bigCarousel.to('slide-' + (this.propertyPhotos.length - 1));
    }
  }
}
