import { Component, Input } from '@angular/core';
import { BootstrapColors } from 'src/app/types/bootstrap-colors';

@Component({
  selector: 'center-spinner',
  templateUrl: './center-spinner.component.html',
  styleUrls: ['./center-spinner.component.css'],
})
export class CenterSpinnerComponent {
  @Input() spinnerColor: BootstrapColors = 'primary';
}
