<div class="center-spinner">
  <div class="spinner-grow text-{{ spinnerColor }}">
    <span class="sr-only">Loading...</span>
  </div>
  <div class="spinner-grow text-{{ spinnerColor }}">
    <span class="sr-only">Loading...</span>
  </div>
  <div class="spinner-grow text-{{ spinnerColor }}">
    <span class="sr-only">Loading...</span>
  </div>
</div>
