import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';
import { FilterPipe } from './filter.pipe';
import { SafePipe } from './safe.pipe';
import { SlugifyPipe } from './slugify.pipe';

@NgModule({
  declarations: [SafePipe, FilterPipe, SlugifyPipe],
  imports: [CommonModule],
  exports: [SafePipe, FilterPipe, SlugifyPipe],
})
export class MainPipe { }
