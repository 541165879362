<header class="header">
  <!-- Navbar-->
  <nav class="navbar navbar-expand-lg shadow navbar-light bg-white">
    <div class="container-fluid">
      <div class="d-flex align-items-center">
        <a [routerLink]="['/']" class="navbar-brand py-1"
          ><img src="/assets/img/mxvLogo.svg" alt="Myexclusive Villas"
        /></a>

        <form
          [formGroup]="searchForm"
          (ngSubmit)="onSubmit()"
          class="form-inline d-none d-sm-flex"
          action="#"
          id="search"
        >
          <div
            class="input-label-absolute input-label-absolute-left input-expand ms-lg-2 ms-xl-3"
          >
            <label class="label-absolute" for="search_search"
              ><i class="fa fa-search"></i
              ><span class="sr-only">Location or Property</span></label
            >
            <input
              class="form-control form-control-sm border-0 shadow-0 bg-gray-200"
              id="search_search"
              formControlName="searchbox"
              placeholder="Location or Property"
              aria-label="Search"
            />
          </div>
        </form>
      </div>
      <button
        class="navbar-toggler navbar-toggler-right"
        type="button"
        data-bs-toggle="collapse"
        data-bs-target="#navbarCollapse"
        aria-controls="navbarCollapse"
        aria-expanded="false"
        aria-label="Toggle navigation"
      >
        <i class="fa fa-bars"></i>
      </button>
      <!-- Navbar Collapse -->
      <div class="collapse navbar-collapse" id="navbarCollapse">
        <form
          class="form-inline mt-4 mb-2 d-sm-none"
          action="#"
          id="searchcollapsed"
          [formGroup]="searchForm"
          (ngSubmit)="onSubmit()"
        >
          <div class="input-label-absolute input-label-absolute-left w-100">
            <label class="label-absolute" for="searchcollapsed_search"
              ><i class="fa fa-search"></i
              ><span class="sr-only">Location or Property</span></label
            >
            <input
              class="form-control form-control-sm border-0 shadow-0 bg-gray-200"
              id="searchcollapsed_search"
              formControlName="searchbox"
              placeholder="Location or Property"
              aria-label="Search"
              type="search"
            />
          </div>
        </form>
        <ul class="navbar-nav ms-auto">
          <li class="nav-item">
            <a class="nav-link" [routerLink]="['/']">Home</a>
          </li>
          <li class="nav-item dropdown">
            <a
              class="nav-link dropdown-toggle"
              id="experiencesDropdownMenuLink"
              href="javascript:void(0)"
              data-bs-toggle="dropdown"
              aria-haspopup="true"
              aria-expanded="false"
            >
              Experiences</a
            >
            <div
              class="dropdown-menu dropdown-menu-end"
              aria-labelledby="experiencesDropdownMenuLink"
            >
              <a class="dropdown-item" href="">Introduction </a>
              <div class="dropdown-divider"></div>
              <a class="dropdown-item" href="">Places</a>
              <div class="dropdown-divider"></div>
              <a class="dropdown-item" href="">Things To Do</a>
            </div>
          </li>

          <li class="nav-item dropdown">
            <a
              class="nav-link dropdown-toggle"
              id="helpDropdownMenuLink"
              href="javascript:void(0)"
              data-bs-toggle="dropdown"
              aria-haspopup="true"
              aria-expanded="false"
            >
              Help</a
            >
            <div
              class="dropdown-menu dropdown-menu-end"
              aria-labelledby="helpDropdownMenuLinkLink"
            >
              <a [routerLink]="['/page/f-a-q']" class="dropdown-item">F.A.Q</a>
              <a [routerLink]="['/contactus']" class="dropdown-item"
                >Contact Us</a
              >
            </div>
          </li>
          <li class="nav-item" style="border-left: 1px solid #efefef">
            <a
              [routerLink]="['/user/favorites']"
              routerLinkActive="router-link-active"
              style="cursor: pointer"
              class="nav-link"
              title="favorites"
            >
              <i class="fas fa-heart text-secondary fs-6"></i>
              <span class="badge badge-secondary-light">{{
                favorites.length
              }}</span>
            </a>
          </li>

          <li
            class="nav-item"
            *ngIf="loggedIn != true"
            style="border-left: 1px solid #efefef"
          >
            <span style="cursor: pointer" class="nav-link" (click)="openLogin()"
              ><i class="fas fa-user"></i> Login</span
            >
          </li>

          <li
            class="nav-item"
            *ngIf="loggedIn != true"
            style="border-left: 1px solid #efefef"
          >
            <span
              style="cursor: pointer"
              class="nav-link"
              (click)="openSignupModal()"
              ><i class="fas fa-user-plus"></i> Sign-Up</span
            >
          </li>

          <li class="nav-item dropdown" *ngIf="loggedIn">
            <a
              class="nav-link dropdown-toggle"
              href="#"
              id="navbarDropdown"
              role="button"
              data-bs-toggle="dropdown"
              aria-expanded="false"
            >
              <i class="fas fa-user"></i>
            </a>
            <ul
              class="dropdown-menu dropdown-menu-end"
              aria-labelledby="navbarDropdown"
            >
              <li>
                <a class="dropdown-item" [routerLink]="['/user/profile']"
                  >My Profile</a
                >
              </li>
              <li>
                <a class="dropdown-item" [routerLink]="['/user/bookings']"
                  >My Bookings</a
                >
              </li>
              <li>
                <a class="dropdown-item" href="#">Refer A Property Owner</a>
              </li>
              <li>
                <a class="dropdown-item" href="#"
                  >Invite friends <small> get a 5% discount</small></a
                >
              </li>
              <li class="dropdown-divider"></li>
              <li>
                <button
                  (click)="logout($event)"
                  class="dropdown-item"
                  style="cursor: pointer"
                >
                  <i class="fas fa-sign-out-alt mr-2 text-muted"></i> Log Out
                </button>
              </li>
            </ul>
          </li>
        </ul>
      </div>
    </div>
  </nav>
  <!-- /Navbar -->
</header>
