import { Inject, Injectable } from '@angular/core';
import { BehaviorSubject, merge, Observable } from 'rxjs';
import { MXV_APP_CONFIG } from './config.constants';

// import { merge } from 'lodash-es';

@Injectable({
  providedIn: 'root',
})
export class MxvConfigService {
  private _config: BehaviorSubject<any>;

  /**
   * Constructor
   */
  constructor(@Inject(MXV_APP_CONFIG) config: any) {
    // Private
    this._config = new BehaviorSubject(config);
  }

  // -----------------------------------------------------------------------------------------------------
  // @ Accessors
  // -----------------------------------------------------------------------------------------------------

  /**
   * Setter & getter for config
   */
  set config(value: any) {
    // Merge the new config over to the current config
    //const config = merge({}, this._config.getValue(), value);
    const config = this._config.getValue();
    // config[attr] = value;
    // Execute the observable
    this._config.next(config);
  }

  // eslint-disable-next-line @typescript-eslint/member-ordering
  get config$(): Observable<any> {
    return this._config.asObservable();
  }

  // -----------------------------------------------------------------------------------------------------
  // @ Public methods
  // -----------------------------------------------------------------------------------------------------
  change(attr: string, value: any) {
    const config = this._config.getValue();
    config[attr] = value;
    // Execute the observable
    this._config.next(config);
  }
  /**
   * Resets the config to the default
   */
  reset(): void {
    // Set the config
    this._config.next(this.config);
  }
}
