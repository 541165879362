// import { shake } from '@fuse/animations/shake';
import {
  slideInBottom,
  slideInLeft,
  slideInRight,
  slideInTop,
  slideOutBottom,
  slideOutLeft,
  slideOutRight,
  slideOutTop,
} from 'src/@tolis/animations/slide';
import { expandCollapse } from 'src/@tolis/animations/expand-collapse';
import { flyInOut } from 'src/@tolis/animations/openclose';
import { zoomIn, zoomOut } from './zoom';
import { fadeInOut, valueChanged } from './fade';
import { accordion } from './accordion';

export const mxvAnimations = [
  slideInTop,
  slideInBottom,
  slideInLeft,
  slideInRight,
  slideOutTop,
  slideOutBottom,
  slideOutLeft,
  slideOutRight,
  flyInOut,
  expandCollapse,
  zoomIn,
  zoomOut,
  fadeInOut,
  valueChanged,
  accordion,
];
