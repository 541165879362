import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { BehaviorSubject, Observable } from 'rxjs';
import { HomePageData } from 'src/app/models/homepage.types';
import { tap } from 'rxjs/operators';
import { environment } from 'src/environments/environment';

@Injectable({
  providedIn: 'root',
})
export class HomeService {
  private _homePageData: BehaviorSubject<HomePageData | null> =
    new BehaviorSubject(null);

  constructor(private _httpClient: HttpClient) {}

  // ---------------------------------------------------------------------------------------------------------------------------
  // @ Accessors
  // ---------------------------------------------------------------------------------------------------------------------------
  get homePageData$(): Observable<HomePageData> {
    return this._homePageData.asObservable();
  }

  // -----------------------------------------------------------------------------------------------------
  // @ Public methods
  // -----------------------------------------------------------------------------------------------------

  showHomeInfo(smallDevice?: boolean): Observable<HomePageData> {
    return this._httpClient
      .get<HomePageData>(
        `${environment.endpoints.api}/v1/homePageInfo/${smallDevice}`
      )
      .pipe(
        tap((homePageData: HomePageData) => {
          this._homePageData.next(homePageData);
        })
      );
  }
}
